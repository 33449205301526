



















































































































































































































































.project {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  .c-icon-plus-circle {
    padding: 0 7px;
    font-size: 18px;
  }

  &__tree-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 480px;
    background-color: #f5f5f5;
  }

  &__search {
    display: flex;
    margin: 5px;

    &-item {
      margin: 0 5px;
    }
  }

  &__tree {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__document {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
